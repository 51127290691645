.task-priority {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	gap: 8px;
	display: flex;
	align-items: center;
	letter-spacing: 0.25px;
	color: #1e293b;
	@apply tt-truncate;
}
.task-priority-icon {
	width: 20px;
	height: 20px;
}
