/**
 * Tailwind's utility classes, generated based on your config file.
 */

@tailwind utilities;

.my-text-overflow-ellipsis {
	overflow: hidden;
	overflow-wrap: anywhere;
}
