.w-md-editor-bar {
	position: absolute !important;
	cursor: s-resize !important;
	right: 0 !important;
	bottom: 0 !important;
	margin-top: -11px !important;
	margin-right: 0 !important;
	width: 14px !important;
	z-index: 3 !important;
	height: 10px !important;
	border-radius: 0 0 3px 0 !important;
	-webkit-user-select: none !important;
	user-select: none !important;
}
.w-md-editor-bar svg {
	display: block !important;
	margin: 0 auto !important;
}
.w-md-editor-aree {
	overflow: auto !important;
	border-radius: 5px !important;
}
.w-md-editor-text {
	min-height: 100% !important;
	position: relative !important;
	text-align: left !important;
	white-space: pre-wrap !important;
	word-break: keep-all !important;
	overflow-wrap: break-word !important;
	box-sizing: border-box !important;
	padding: 10px !important;
	margin: 0 !important;
	font-size: 14px !important;
	line-height: 18px !important;
	-webkit-font-variant-ligatures: common-ligatures !important;
	font-variant-ligatures: common-ligatures !important;
}
.w-md-editor-text-pre,
.w-md-editor-text-input,
.w-md-editor-text > .w-md-editor-text-pre {
	margin: 0 !important;
	border: 0 !important;
	background: none !important;
	box-sizing: inherit !important;
	display: inherit !important;
	font-family: inherit !important;
	font-size: inherit !important;
	font-style: inherit !important;
	-webkit-font-variant-ligatures: inherit !important;
	font-variant-ligatures: inherit !important;
	font-weight: inherit !important;
	letter-spacing: inherit !important;
	line-height: inherit !important;
	tab-size: inherit !important;
	text-indent: inherit !important;
	text-rendering: inherit !important;
	text-transform: inherit !important;
	white-space: inherit !important;
	overflow-wrap: inherit !important;
	word-break: inherit !important;
	word-break: normal !important;
	padding: 0 !important;
}
.w-md-editor-text-pre > code,
.w-md-editor-text-input > code,
.w-md-editor-text > .w-md-editor-text-pre > code {
	font-family: inherit !important;
}
.w-md-editor-text-pre {
	position: relative !important;
	margin: 0px !important;
	pointer-events: none !important;
	background-color: transparent !important;
}
.w-md-editor-text-pre > code {
	padding: 0 !important;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-size: 14px !important;
	line-height: 18px !important;
}
.w-md-editor-text-input {
	position: absolute !important;
	top: 0px !important;
	left: 0px !important;
	height: 100% !important;
	width: 100% !important;
	resize: none !important;
	color: inherit !important;
	overflow: hidden !important;
	outline: 0 !important;
	padding: inherit !important;
	-webkit-font-smoothing: antialiased !important;
	-webkit-text-fill-color: transparent !important;
}
.w-md-editor-text-input:empty {
	-webkit-text-fill-color: inherit !important;
}
.w-md-editor-text-pre,
.w-md-editor-text-input {
	word-wrap: pre !important;
	word-break: break-word !important;
	white-space: pre-wrap !important;
}
/**
 * Hack to apply on some CSS on IE10 and IE11
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/**
    * IE doesn't support '-webkit-text-fill-color'
    * So we use 'color: transparent' to make the text transparent on IE
    * Unlike other browsers, it doesn't affect caret color in IE
    */
	.w-md-editor-text-input {
		color: transparent !important;
	}
	.w-md-editor-text-input::selection {
		background-color: #accef7 !important;
		color: transparent !important;
	}
}
.w-md-editor-text-pre .punctuation {
	color: var(--color-prettylights-syntax-comment) !important;
}
.w-md-editor-text-pre .token.url,
.w-md-editor-text-pre .token.content {
	color: var(--color-prettylights-syntax-constant) !important;
}
.w-md-editor-text-pre .token.title.important {
	color: var(--color-prettylights-syntax-markup-bold) !important;
}
.w-md-editor-text-pre .token.code-block .function {
	color: var(--color-prettylights-syntax-entity) !important;
}
.w-md-editor-text-pre .token.bold {
	font-weight: unset !important;
}
.w-md-editor-text-pre .token.title {
	line-height: unset !important;
	font-size: unset !important;
	font-weight: unset !important;
}
.w-md-editor-text-pre .token.code.keyword {
	color: var(--color-prettylights-syntax-constant) !important;
}
.w-md-editor-text-pre .token.strike,
.w-md-editor-text-pre .token.strike .content {
	color: var(--color-prettylights-syntax-markup-deleted-text) !important;
}
.w-md-editor-toolbar-child {
	position: absolute !important;
	border-radius: 3px !important;
	box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default),
		0 1px 1px var(--color-border-default) !important;
	background-color: var(--color-canvas-default) !important;
	z-index: 1 !important;
	display: none !important;
}
.w-md-editor-toolbar-child.active {
	display: block !important;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar {
	border-bottom: 0 !important;
	padding: 3px !important;
	border-radius: 3px !important;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
	display: block !important;
}
.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
	width: -webkit-fill-available !important;
	height: initial !important;
	box-sizing: border-box !important;
	padding: 3px 4px 2px 4px !important;
	margin: 0 !important;
}
.w-md-editor-toolbar {
	border-bottom: 1px solid var(--color-border-default) !important;
	background-color: var(--color-canvas-default) !important;
	padding: 5px 5px !important;
	display: flex !important;
	gap: 1em;
	justify-content: space-between !important;
	align-items: center !important;
	border-radius: 3px 3px 0 0 !important;
	-webkit-user-select: none !important;
	user-select: none !important;
	flex-wrap: wrap !important;
}

.w-md-editor-toolbar svg {
	width: 100%;
	height: auto;
}

.w-md-editor-toolbar.bottom {
	border-bottom: 0px !important;
	border-top: 1px solid var(--color-border-default) !important;
	border-radius: 0 0 3px 3px !important;
}
.w-md-editor-toolbar ul,
.w-md-editor-toolbar li {
	margin: 0 !important;
	padding: 0 !important;
	list-style: none !important;
	line-height: initial !important;
}
.w-md-editor-toolbar li {
	display: inline-block !important;
	font-size: 14px !important;
}
.w-md-editor-toolbar li + li {
	margin: 0 !important;
}
.w-md-editor-toolbar li > button {
	border: none !important;
	height: 20px !important;
	/*height: 30px !important;*/
	aspect-ratio: 1/1;
	line-height: 20px !important;
	background: none !important;
	padding: 4px !important;
	/*padding: 8px !important;*/
	margin: 0 1px !important;
	border-radius: 2px !important;
	text-transform: none !important;
	font-weight: normal !important;
	overflow: visible !important;
	outline: none !important;
	cursor: pointer !important;
	transition: all 0.3s !important;
	white-space: nowrap !important;
	color: var(--color-fg-default) !important;
}

.w-md-editor-toolbar li > button:hover,
.w-md-editor-toolbar li > button:focus {
	background-color: var(--color-neutral-muted) !important;
	color: var(--color-accent-fg) !important;
}
.w-md-editor-toolbar li > button:active {
	background-color: var(--color-neutral-muted) !important;
	color: var(--color-danger-fg) !important;
}
.w-md-editor-toolbar li > button:disabled {
	color: var(--color-border-default) !important;
	cursor: not-allowed !important;
}
.w-md-editor-toolbar li > button:disabled:hover {
	background-color: transparent !important;
	color: var(--color-border-default) !important;
}
.w-md-editor-toolbar li.active > button {
	color: var(--color-accent-fg) !important;
	background-color: var(--color-neutral-muted) !important;
}
.w-md-editor-toolbar-divider {
	height: 14px !important;
	width: 1px !important;
	margin: -3px 3px 0 3px !important;
	vertical-align: middle !important;
	background-color: var(--color-border-default) !important;
}
.w-md-editor {
	text-align: left !important;
	border-radius: 3px !important;
	padding-bottom: 1px !important;
	position: relative !important;
	color: var(--color-fg-default) !important;
	box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default),
		0 1px 1px var(--color-border-default) !important;
	background-color: var(--color-canvas-default) !important;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	display: flex !important;
	flex-direction: column !important;
}
.w-md-editor-toolbar {
	height: -webkit-fit-content !important;
	height: fit-content !important;
}
.w-md-editor-content {
	height: 100% !important;
	overflow: auto !important;
	position: relative !important;
	border-radius: 0 0 3px 0 !important;
}
.w-md-editor .copied {
	display: none !important;
}
.w-md-editor-input {
	width: 50% !important;
	height: 100% !important;
}
.w-md-editor-text-pre > code {
	word-break: break-word !important;
	white-space: pre-wrap !important;
}
.w-md-editor-preview {
	width: 50% !important;
	box-sizing: border-box !important;
	box-shadow: inset 1px 0 0 0 var(--color-border-default) !important;
	position: absolute !important;
	padding: 10px 20px !important;
	overflow: auto !important;
	top: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	border-radius: 0 0 5px 0 !important;
	display: flex !important;
	flex-direction: column !important;
}
.w-md-editor-preview .anchor {
	display: none !important;
}
.w-md-editor-preview .contains-task-list {
	list-style: none !important;
}
.w-md-editor-show-preview .w-md-editor-input {
	width: 0% !important;
	overflow: hidden !important;
	background-color: var(--color-canvas-default) !important;
}
.w-md-editor-show-preview .w-md-editor-preview {
	width: 100% !important;
	box-shadow: inset 0 0 0 0 !important;
}
.w-md-editor-show-edit .w-md-editor-input {
	width: 100% !important;
}
.w-md-editor-show-edit .w-md-editor-preview {
	width: 0% !important;
	padding: 0 !important;
}
.w-md-editor-fullscreen {
	overflow: hidden !important;
	position: fixed !important;
	z-index: 99999 !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	display: block;
	bottom: 0 !important;
	height: 100% !important;
}
.w-md-editor-fullscreen .w-md-editor-content {
	height: 100% !important;
}
.md-left.w-md-editor-fullscreen {
	left: 60px !important;
}
