.task-tags-cell {
}
.task-tag {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 12px;
	gap: 10px;
	height: 26px;
	background: linear-gradient(0deg, rgba(143, 168, 167, 0.16), rgba(143, 168, 167, 0.16)), #fafdfd;
	border-radius: 100px;

	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;

	letter-spacing: 0.5px;

	color: #64748b;
	@apply tt-truncate;
}
