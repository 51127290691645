.input-container {
	@apply tt-flex tt-flex-col tt-w-full;
}
.input-label {
	@apply tt-text-14 tt-font-semibold tt-mb-4;
}
.input-error {
	@apply tt-text-12 tt-text-red-600 tt-pl-16;
	@apply tt-min-h-10;
}
