.button {
	@apply tt-scale-0 tt-duration-200 tt-absolute tt-cursor-pointer tt-bg-white tt-bottom-6 tt-rounded-full tt-flex tt-items-center tt-justify-center tt-right-6 tt-w-28 tt-h-28;
}

.button:hover {
	@apply tt-bg-gray-100;
}

.gridItem {
	@apply tt-relative;
	&:hover {
		.button {
			@apply tt-scale-100;
		}
	}
}
