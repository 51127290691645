.EmptyProjectTemplateTasksList {
	font-weight: 400;
	color: rgb(107 114 128);
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 4.5rem;
	border: 1px solid rgb(224 224 224 / 0.7);
	background-color: rgb(243 244 246 / 0.7);
	border-radius: 0.8rem;
	margin-bottom: 0.1rem;
	padding: 0.2rem 0.8rem;
	gap: 0.8rem;
}
