.project-info {
}

.project-fields {
	@apply tt-grid-cols-1 md:tt-grid-cols-2 tt-grid tt-gap-16;
}

.project-fields-wrap {
	@apply tt-grid-cols-1 md:tt-grid-cols-1;
}
