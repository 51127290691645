.task-incoming-toggle {
	height: 30px;
	svg {
		height: 20px;
	}
	button {
		width: 35px;
		border-radius: 35px;
	}
}

.task-incoming-toggle-selected {
	svg {
		@apply tt-text-primary;
	}
}
