.table-menu-item {
	padding: 10px 16px 10px 12px;
	@apply tt-rounded-full tt-flex tt-items-center tt-justify-center tt-gap-8 tt-h-40 tt-cursor-pointer tt-duration-150;
	&:hover {
		background: rgba(143, 168, 167, 0.12);
	}
}

.menu-label {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1e293b;
}

.table-menu-item-active {
	background: linear-gradient(0deg, rgba(143, 168, 167, 0.12), rgba(143, 168, 167, 0.12)), #fafdfd;
	&:hover {
		background: linear-gradient(0deg, rgba(143, 168, 167, 0.12), rgba(143, 168, 167, 0.12)), #fafdfd;
	}

	.menu-label {
		color: #0f766e;
	}
	.menu-icon {
		svg {
			color: #0f766e;
		}
	}
}
