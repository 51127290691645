.team-settings {
	@apply tt-max-w-5xl tt-mx-auto tt-flex tt-flex-col tt-gap-32 tt-pb-32;
}

.team-block {
	@apply tt-flex tt-flex-col tt-gap-12;
}
.block-label {
	@apply tt-text-16 tt-font-semibold tt-border-b tt-border-gray-200 tt-pb-8;
}
