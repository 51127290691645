.row_wrapper {
	display: flex;
	width: 100%;
	height: fit-content;
	padding-bottom: 0.8rem;
}

.row {
	display: flex;
	text-align: center;
	width: 100%;
	min-width: fit-content;
	height: 3.6rem;
	padding: 0.8rem;
	align-items: center;
	background-color: #f9f9f9;
	border: solid 0.1rem;
	border-color: #eeeeee;
	border-radius: 0.8rem;
	color: #999999;
	font-weight: 500;
	user-select: none;
}
