.Gallery {
	margin: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100vw;
	max-height: 100vh;
}

.Gallery img {
	object-fit: contain;
	max-width: 90vw;
	max-height: 90vh;
}

.Gallery video {
	object-fit: contain;
	max-width: 90vw;
	max-height: 90vh;
}

.Gallery__controlPanel {
	display: flex;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	position: absolute;
	bottom: 2rem;
	height: 5rem;
	border-radius: 2.5rem;
	width: auto;
	border: solid 0.1rem rgba(255, 255, 255, 0.5);
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0.1;
	transition: all 100ms;
	z-index: 999;
}

.Gallery__controlPanel:hover {
	opacity: 0.8;
}

.Gallery__controlButton {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 4rem;
	height: 4rem;
	border-radius: 2rem;
	border: solid 0.1rem white;
	color: white;
	opacity: 0.5;
}

.Gallery__itemIndex {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 4rem;
	height: 4rem;
	border-radius: 2rem;
	border: solid 0.1rem white;
	color: white;
}

.Gallery__controlButton:hover {
	opacity: 1;
}

.Gallery__itemTitle {
	position: absolute;
	top: 2rem;
}

@media (max-width: 1200px) {
	.Gallery img {
		max-width: 100vw;
		max-height: 100vh;
	}

	.Gallery video {
		max-width: 100vw;
		max-height: 100vh;
	}
}
