.ProjectTemplateTasksList__header {
	display: flex;
	width: 100%;
	height: 5rem;
	gap: 0.8rem;
}

.ProjectTemplateTasksList__title {
	font-size: 1.6rem;
	font-weight: 500;
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
}

.ProjectTemplateTasksList__control_panel {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
}
