.table-pagination {
	@apply tt-flex tt-items-center tt-justify-between tt-gap-16;
}
.pagination-side {
	@apply tt-flex tt-items-center tt-gap-16;
}
.pagination-label {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	display: flex;
	align-items: flex-end;
	text-align: center;
	letter-spacing: 0.25px;

	/* Text/--fuse-text-hint */

	color: #64748b;
}

.pagination-enter-page,
.pagination-page-size {
	@apply tt-flex tt-items-center tt-gap-16;
}

.pagination-enter-input {
	width: 64px;
	height: 32px;
	border: 1px solid #e2e8f0;
	border-radius: 4px;
	background: transparent;
	padding: 8px;

	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	letter-spacing: 0.25px;
	color: #94a3b8;
}
