.LanguageToggler {
	display: flex;
	position: absolute;
	top: 1rem;
	right: 1rem;
	align-items: center;
	justify-content: center;
	min-width: 3rem;
	height: 2.5rem;
	padding: 0 0.5rem;
	border-radius: 2rem;
	background-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	transition: background-color 250ms;
	border: solid 1px rgba(255, 255, 255, 0.75);
}

.LanguageToggler:hover {
	background-color: rgba(255, 255, 255, 0.75);
}
