.row_wrapper {
	display: flex;
	width: 100%;
	height: fit-content;
	padding-bottom: 0.8rem;
}

.row {
	display: flex;
	width: 100%;
	min-width: fit-content;
	height: 2.8rem;
	background-color: #f9fafb;
	user-select: none;
	font-weight: 400;
	padding: 0 0.8rem;
	gap: 0.8rem;
	align-items: center;
}
