.pagination-container {
	display: flex;
	list-style-type: none;

	.pagination-item {
		@apply tt-duration-200;
		padding: 0 11px;

		text-align: center;
		border: 1px solid transparent;
		margin: auto 2px;
		color: #64748b;
		display: flex;
		box-sizing: border-box;
		align-items: center;

		border-radius: 16px;
		min-width: 32px;
		min-width: 32px;
		height: 32px;

		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.1px;

		&.dots:hover {
			background-color: transparent;
			cursor: default;
		}

		&:hover {
			border: 1px solid #cbd5e1;
			cursor: pointer;
		}

		&.selected {
			border: 1px solid #cbd5e1;
		}

		.arrow {
			&::before {
				position: relative;
				/* top: 3pt; Uncomment this to lower the icons as requested in comments*/
				content: '';
				/* By using an em scale, the arrows will size with the font */
				display: inline-block;
				width: 0.4em;
				height: 0.4em;
				border-right: 0.12em solid #64748b;
				border-top: 0.12em solid #64748b;
				transform: scale(1.4);
			}

			&.left {
				transform: rotate(-135deg) translate(-50%);
			}

			&.right {
				transform: rotate(45deg);
			}
		}

		&.disabled {
			pointer-events: none;

			.arrow::before {
				border-right: 0.12em solid #cbd5e1;
				border-top: 0.12em solid #cbd5e1;
			}

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}
}
