.table-sort {
}
.table-sort-modal {
	@apply tt-p-24;
	width: 500px;
}
.table-sort-footer {
	@apply tt-flex tt-items-center tt-gap-8 tt-justify-end tt-w-full tt-pt-24;
}

.table-sort-icon {
	width: 18px;
	height: 18px;
}
.table-sort-buttons {
	@apply tt-flex tt-items-center tt-justify-start tt-w-full tt-pt-16;
}
.table-sort-field {
	@apply tt-flex tt-gap-16 tt-items-center;
}
.table-sort-field-delete-icon {
	width: 24px;
	height: 24px;
}

.table-sort-fields {
	@apply tt-flex tt-flex-col tt-gap-16;
}
