.MediaViewer {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100000;
}

.MediaViewer__background {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	backdrop-filter: blur(5px);
}

.MediaViewer__closeButton {
	position: absolute;
	top: 2rem;
	right: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5rem;
	height: 5rem;
	border-radius: 2.5rem;
	border: solid 0.1rem white;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	opacity: 0.1;
	transition: all 100ms;
}

.MediaViewer__closeButton:hover {
	opacity: 0.8;
}
