.team-info {
}

.team-fields {
	@apply tt-grid-cols-1 md:tt-grid-cols-2 tt-grid tt-gap-16;
}

.team-fields-wrap {
	@apply tt-grid-cols-1 md:tt-grid-cols-1;
}
