.avatar {
	@apply tt-w-24 tt-h-24 tt-min-w-24 tt-min-h-24;
}

.avatar-img {
	@apply tt-w-24 tt-h-24  tt-rounded-full tt-object-cover;
}

.avatar-placeholder {
	@apply tt-w-24 tt-h-24  tt-rounded-full tt-object-cover;
	letter-spacing: 1px;
}

.avatar-placeholder {
	@apply tt-w-24 tt-h-24 tt-flex   tt-items-center tt-justify-center tt-border tt-bg-primary-500/10 tt-border-primary-500/5 tt-text-primary-500;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.5px;
}
