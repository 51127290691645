.table-search {
	@apply tt-gap-12 tt-flex tt-items-center tt-w-full;
}

// TODO: Refactor
.search-icon {
	width: 22px;
	height: 22px;
	color: #94a3b8;
}

.search-icon-container {
	width: 32px;
	height: 32px;
	@apply tt-flex tt-items-center tt-justify-center;
}

.search-input {
	@apply tt-bg-transparent;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.1px;

	&::placeholder {
		color: #94a3b8;
	}
}
