.filed-row {
	@apply tt-flex;
}
.filed-label {
	@apply tt-flex tt-text-14 tt-text-gray-500 tt-pr-12 tt-min-w-256 tt-w-256;
}

.filed-content {
	@apply tt-font-medium  tt-text-14;
}
