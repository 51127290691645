.main_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
	gap: 1rem;
	padding: 1rem;
	justify-content: center;
	transition: background-color 250ms;
	@apply tt-bg-gray-100 tt-rounded-md tt-border-1 tt-border-dashed tt-border-gray-400;
}

.main_container__expanded {
	@apply tt-bg-white;
}

.toolbar {
	display: flex;
	gap: 1rem;
	font-weight: 500;
	align-items: center;
	justify-content: center;
}

.control_button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	padding: 0 1rem;
	height: 3.2rem;
	user-select: none;
	border-radius: 9999px;
	transition: background-color 250ms, color 250ms;
}

.restore_button {
	@apply tt-bg-primary-700 tt-text-white;
}

.clear_button {
	@apply tt-bg-gray-200 tt-text-black;
}

.clear_button:hover {
	@apply tt-bg-gray-300;
}

.expand_button {
	@apply tt-text-gray-400;
}

.expand_button:hover {
	@apply tt-text-primary-700;
}

.changes_preview_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0rem 1rem;
	max-height: 30rem;
	overflow-y: auto;
}

.changes_node {
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	@apply tt-text-grey-700;
}

.changes_node__title {
	display: flex;
	font-weight: 500;
}

.changes_node__content {
	display: flex;
}

.warning_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	@apply tt-text-primary-600;
}
