.ProjectTemplateTasksListItem {
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	min-height: 3.5rem;
	border: 1px solid rgb(224 224 224 / 0.7);
	background-color: rgb(243 244 246 / 0.7);
	border-radius: 0.8rem;
	padding: 0.2rem 0.8rem;
	cursor: pointer;
	transition: all 250ms;
	gap: 0.8rem;
}

.ProjectTemplateTasksListItem span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ProjectTemplateTasksListItem:hover {
	background-color: rgb(229 231 235 / 0.5);
}

.ProjectTemplateTasksListItem__slug {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.4rem;
	font-weight: 500;
	font-size: 1.3rem;
	color: rgb(100 116 139);
	width: 6.5rem;
	background-color: rgb(226 232 240);
	padding: 0.2rem 0.5rem;
	box-sizing: border-box;
	border-radius: 1.25rem;
}

.ProjectTemplateTasksListItem__title {
	display: flex;
	width: 24rem;
}

.ProjectTemplateTasksListItem__spacer {
	display: flex;
	flex-grow: 1;
}

.ProjectTemplateTasksListItem__user_show {
	display: flex;
	align-items: center;
	width: 16rem;
	justify-content: flex-start;
}

.ProjectTemplateTasksListItem__status {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	font-weight: 500;
	font-size: 1.2rem;
	color: rgb(255 255 255);
	width: 9rem;
	padding: 0.2rem 0.5rem;
	box-sizing: border-box;
	border-radius: 1.25rem;
}

.ProjectTemplateTasksListItem__delete_btn {
	color: rgb(100 116 139);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	transition: all 250ms;
}

.ProjectTemplateTasksListItem__delete_btn:hover {
	color: rgb(56, 65, 78);
}
