.button {
	@apply tt-rounded-full  tt-duration-150;
	@apply tt-border tt-border-transparent;
	@apply tt-flex tt-gap-8 tt-items-center tt-justify-center;
}

.small {
	@apply tt-h-24 tt-px-6 tt-py-2.5 tt-font-medium tt-text-12;
}

.medium {
	@apply tt-h-32 tt-px-16 tt-py-5 tt-font-medium tt-text-14;
}

.large {
	@apply tt-h-44 tt-px-24 tt-py-10 tt-font-semibold tt-text-16;
}

.contained {
	@apply tt-bg-primary hover:tt-bg-primary-600 tt-text-white;
}

.outlined {
	@apply tt-border-gray-200 hover:tt-bg-gray-200;
}
.text {
	@apply hover:tt-bg-gray-200;
}
.disabled {
	@apply tt-bg-gray-200 hover:tt-bg-gray-200 tt-text-gray-500 tt-cursor-not-allowed;
}
