.comment {
	@apply tt-w-full;
}

.comment-card-header-actions {
	@apply tt-flex tt-items-center tt-gap-2 tt-scale-0 tt-duration-100;
}

.comment-card-container {
	@apply tt-flex tt-mb-8 tt-w-full;
}

.comment-card {
	@apply tt-border tt-rounded-lg tt-p-10 tt-pt-6 tt-w-full;
	&:hover {
		.comment-card-header-actions {
			@apply tt-scale-100;
		}
	}
}

.comment-card-avatar {
	@apply tt-w-36 tt-h-36 tt-min-w-36 tt-flex tt-items-center tt-justify-between tt-pt-6;
}

.comment-card-header {
	@apply tt-flex tt-justify-between tt-items-center tt-gap-12 tt-mb-4;
}

.comment-card-info {
	@apply tt-flex tt-items-center tt-gap-8;
}

.comment-card-info-date {
	@apply tt-text-12 tt-text-grey-700;
}

.comment-card-info-name {
	@apply tt-font-bold;
}

.comment-child-comments {
	@apply tt-flex tt-flex-col tt-w-full;
}
