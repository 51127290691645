.row_wrapper {
	display: flex;
	width: 100%;
	height: fit-content;
	padding-bottom: 0.8rem;
}

.row {
	display: flex;
	width: 100%;
	min-width: fit-content;
	height: 3.6rem;
	user-select: none;
	font-weight: 500;
	padding: 0 0.8rem;
	gap: 0.8rem;
	align-items: center;
	border: solid 0.1rem;
	border-radius: 0.8rem;
	transition: background-color 250ms;
}

.row_active {
	background-color: #f5f6f8;
	border-color: #eeeeee;
	cursor: pointer;
}

.row_active:hover {
	background-color: #edf1f5;
	border-color: #e2e8f0;
}

.row_choosen {
	background-color: #edf1f5;
	border-color: #e2e8f0;
}
