.cell_wrapper {
	display: flex;
	height: 100%;
	flex-shrink: 0;
	align-items: center;
	overflow: hidden;
	border-radius: 999rem;
	background-color: #f9f9f9;
	animation: loadingDummy ease-in-out 2500ms infinite;
}

@keyframes loadingDummy {
	0% {
		background-color: #f9f9f9;
	}
	25% {
		background-color: #f0f0f0;
	}
	50% {
		background-color: #f9f9f9;
	}
	75% {
		background-color: #eeeeee;
	}
	100% {
		background-color: #f9f9f9;
	}
}
