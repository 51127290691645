.sidebar-wrapper {
}
.sidebar {
	@apply tt-max-w-640 tt-w-full tt-bg-white  tt-border-l-2 tt-h-full;
	@apply tt-absolute -tt-right-full tt-top-0 tt-duration-300 tt-ease-in-out;
}

.sidebar-open {
	@apply tt-right-0;
}
