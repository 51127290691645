.card_content_wrapper {
	position: relative;
	user-select: none;
	display: flexbox;
	transition: height 250ms;
}

.card_sticky {
	position: fixed;
	display: block;
	pointer-events: none;
	opacity: 0.75;
	z-index: 999;
	transition: transform 250ms, opacity 250ms;
}

.column_content_wrapper {
	position: relative;
	user-select: none;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	height: auto;
	transition: width 250ms;
}

.column_sticky {
	position: fixed;
	display: block;
	pointer-events: none;
	z-index: 999;
	transition: transform 250ms, opacity 250ms;
}

.transformer {
	transition: transform 250ms, opacity 250ms;
}

.card_rejected {
	animation: reject ease-out 300ms;
	animation-iteration-count: 1;
	pointer-events: none;
}

@keyframes reject {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	25% {
		transform: translateX(2rem) scale(0.95);
		opacity: 0.25;
	}
	50% {
		transform: translateX(-1.5rem) scale(0.97);
		opacity: 0.5;
	}
	75% {
		transform: translateX(0.5rem) scale(0.99);
		opacity: 0.75;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.card_drop_control {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: grabbing;
}

.empty_card_placeholder {
	display: flex;
	height: 0.1rem;
	transition: height 250ms;
}

.empty_card_placeholder_await {
	height: 10rem;
	cursor: grabbing;
}

.empty_card_placeholder_await:hover {
	height: 20rem;
}

.card_drag_over {
	transform: perspective(100vw) rotateX(30deg) translateY(1.5rem) scale(0.95);
}

.column_drag_over {
	transform: perspective(300vh) rotateY(-30deg) translateX(3.5rem) scale(0.975);
}

.drop_control {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: grabbing;
}
