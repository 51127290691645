.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after,
.react-calendar__wrap,
.react-calendar__wrap *,
.react-calendar__wrap *:before,
.react-calendar__wrap *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.react-calendar__wrap {
	padding: 25px;
	background: white;
	font-family: 'Inter var', sans-serif;
	font-size: 14px;
	width: 900px;
	color: #1e293b;
}

.react-calendar__wrap button {
	transition: background-color 100ms, color 50ms;
}

.react-calendar__head {
	font-weight: 700;
	font-size: 1.25em;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 1em;
	position: relative;
}

.react-calendar {
	--primary-color: #0f766e;
	--primary-light-color: #ccfbf1;
	--secondary-color: #64748b;
	--secondary-light-color: #64748b20;
	width: 350px;
	max-width: 100%;
}

.react-calendar--doubleView {
	width: 700px;
}

.react-calendar--tripleView {
	width: 900px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	display: flex;
	height: 40px;
	margin: 1em 0;
	color: var(--secondary-color);
	font-weight: 600;
	font-style: normal;
}

.react-calendar__navigation__arrow {
	font-size: 1.5em;
	padding-bottom: 0.1em;
	border-radius: 50%;
	font-weight: inherit;
}

.react-calendar--tripleView .react-calendar__navigation__label--show-three {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1em;
	text-transform: capitalize;
	font-weight: inherit;
}

.react-calendar--tripleView button.react-calendar__navigation__label:disabled {
	background: none;
	font-weight: inherit;
}

.react-calendar__navigation button {
	min-width: 40px;
	background: none;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover {
	background-color: var(--secondary-light-color);
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: capitalize;
	font-weight: bold;
	color: var(--secondary-color);
	padding: 0.25em 0;
	margin-bottom: 0.25em;
	font-size: 0.8em;
	user-select: none;
	border-bottom: 1px solid rgba(100, 116, 139, 0.25);
}

.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	/*font-size: 0.75em;*/
	font-weight: bold;
}

/*.react-calendar__month-view__days__day--weekend {*/
/*    !*color: rgba(100, 116, 139, 1);*!*/
/*}*/

.react-calendar__month-view__days__day--neighboringMonth {
	color: rgba(100, 116, 139, 0.5);
}

/*.react-calendar__year-view .react-calendar__tile,*/
/*.react-calendar__decade-view .react-calendar__tile,*/
/*.react-calendar__century-view .react-calendar__tile {*/
/*    !*padding: 1em 0.5em;*!*/
/*}*/

.react-calendar__tile {
	position: relative;
	margin-bottom: 2px !important;
	transform-style: preserve-3d;
	max-width: 100%;
	padding: 1em 0.5em;
	background: none;
	text-align: center;
	border-radius: 6em;
	text-transform: capitalize;
	/*border-bottom-left-radius: 50%;*/
	/*border-top-left-radius: 50%;*/
	/*line-height: 16px;*/
}

.react-calendar__month-view__days__day {
	aspect-ratio: 1/1;
	/*border-radius: 50%;*/
	padding: 0.5em;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover {
	background-color: var(--primary-color);
	color: white;
}

.react-calendar__tile--now {
	outline: 1px solid var(--primary-color) !important;
	outline-offset: -1px;
}

/*.react-calendar__tile--now:enabled:hover,*/
/*.react-calendar__tile--now:enabled:focus {*/

/*    !*background: #ffffa9;*!*/
/*}*/

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
	transform-style: preserve-3d;
	position: relative;
	color: white !important;
	background: var(--primary-color) !important;
	/*border-radius: 50% !important;*/
	/*z-index: 1;*/
}

.react-calendar__tile--hasActive {
	background: var(--primary-color);
	color: white;
}

/*.react-calendar__tile--hasActive:enabled:hover,*/
/*.react-calendar__tile--hasActive:enabled:focus {*/
/*    !*background: #a9d4ff;*!*/
/*}*/

.react-calendar__tile--active {
	background: var(--primary-light-color);
	color: white;
	/*border-radius: initial;*/
}

.react-calendar__tile--active.react-calendar__tile--range {
	/*border-radius: initial;*/
	position: relative;
	/*background-color: #4f46e5;*/
	color: inherit;
	transform-style: preserve-3d;
}

.react-calendar__tile--active.react-calendar__tile--range::after,
.react-calendar__tile--hover:after,
.react-calendar__tile.inRange:after {
	/*border-radius: initial;*/
	content: '';
	/*box-sizing: border-box;*/
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	/*right: 0;*/
	width: 100%;
	transform: translateZ(-1px);
	background-color: var(--primary-light-color);
	/*background-color: #4f46e5;*/
	color: inherit;
}

.react-calendar__tile--rangeEnd::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 50% !important;
	transform: translateZ(-1px);
	background-color: var(--primary-light-color);
}

.react-calendar__tile--rangeStart::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50% !important;
	width: 50% !important;
	transform: translateZ(-1px);
	background-color: var(--primary-light-color);
}

/*.react-calendar__tile--active:enabled:hover,*/
/*.react-calendar__tile--active:enabled:focus {*/
/*    !*background: #1087ff;*!*/
/*}*/

/*.react-calendar--selectRange */
/*.react-calendar__tile--hover, .react-calendar__tile.inRange {*/
/*    !*background-color: var(--primary-light-color);*!*/
/*    !*border-radius: initial;*!*/
/*}*/

.react-calendar__viewToggle-container {
	border-radius: 6.25rem;
	background: #e2e8f0;
	padding: 2px;
	display: inline-flex;
	gap: 2px;
	/*justify-content: space-around;*/
	margin: 0;
}

.react-calendar__viewToggle-button {
	/*font-family: "Inter var", sans-serif;*/
	/*font-size: 1rem;*/
	/*line-height: 1.25rem;*/
	padding: 0.625em 1.25em;
	color: #1e293b;
	/*font-style: normal;*/
	font-weight: 600;
	border-radius: inherit;
}

.react-calendar__viewToggle-button:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.react-calendar__viewToggle-button.--active {
	background: white;
	border: 0;
	box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 6%), 0 0.0625rem 0.1875rem rgb(0 0 0 / 10%);
}

.react-calendar__selected-dates__wrap {
	font-weight: 600;
	float: right;
}

.react-calendar__selected-dates__input-wrap + .react-calendar__selected-dates__input-wrap {
	margin-left: 2em;
}

.react-calendar__selected-dates__input-wrap input {
	width: 7.5em;
	height: 2.625em;
	padding: 0.6875em 1rem;
	border: 0.0625em solid #cbd5e1;
	background-color: #fff;
	/*margin-top: 0 !important;*/
	margin-left: 1em;
	border-radius: 0.375em;
	outline: none;
	box-sizing: border-box;
}
