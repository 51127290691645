.un-table {
	@apply tt-w-full tt-flex tt-flex-col tt-font-medium;
}
.un-table-head-tr {
	@apply tt-flex tt-items-center tt-mb-[8px] tt-px-6 tt-gap-6;
}
.un-table-cell {
}

.un-table-body-tr {
	@apply tt-flex tt-px-6 tt-gap-6 tt-items-center tt-mb-[8px] tt-h-[36px] tt-relative  tt-bg-gray-100/70 hover:tt-border-gray-200 hover:tt-bg-gray-200/50 tt-rounded-lg tt-border tt-cursor-pointer;
}

.un-table-row-active {
	@apply tt-bg-gray-200/50 hover:tt-bg-gray-200/50 tt-border-gray-200 hover:tt-border-gray-200;
}
.un-table-row-disable {
	@apply tt-bg-gray-100/70 hover:tt-border-gray-200/70 hover:tt-bg-gray-100/70 tt-cursor-not-allowed;
}
.un-table-body-td {
}
