.task-type-cell {
	padding: 5px 12px;
	height: 26px;
	background: #e0f2fe;
	border-radius: 100px;
	@apply tt-inline-flex tt-items-center tt-justify-center;

	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;

	letter-spacing: 0.5px;
}
