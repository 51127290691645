.user-show {
	@apply tt-flex tt-items-center tt-gap-8 tt-truncate;
}
.user-name {
	@apply tt-truncate;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	letter-spacing: 0.25px;
	color: #1e293b;
}

.user-email {
	@apply tt-text-gray-500 tt-text-12;
}
