.table-head-cell {
	@apply tt-flex tt-h-48 tt-py-14 tt-px-16 tt-gap-8 tt-whitespace-nowrap;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #64748b;
}

.table-head-cell-th {
	background: #f5f5f5;
}
